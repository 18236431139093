<template>
  <div class="invitation-bounty-withdraw">
    <div class="balance-box">
      <div class="balance-label">可提现金额（元）</div>
      <div class="amount">{{ availableAmount.toFixed(2) }}</div>
      <div class="withdraw-btn" @click="withdraw">提现</div>
      <div class="withdraw-tips">
        <svg
          t="1611487934591"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4157"
          width="0.24rem"
          height="0.24rem"
        >
          <path
            d="M512 64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z m0 752a36 36 0 1 1 36-36 36 36 0 0 1-36 36z m51.83-551.95L548 636a36 36 0 0 1-72 0l-15.83-371.95c-0.1-1.33-0.17-2.68-0.17-4.05a52 52 0 0 1 104 0c0 1.37-0.07 2.72-0.17 4.05z"
            p-id="4158"
            fill="#B5B5B5"
          ></path>
        </svg>
        <span style="margin-left: 0.04rem">每个自然月可申请提现10次，每次提现不少于10元</span>
      </div>
    </div>
    <div class="tabs">
      <div
        class="tab-item"
        @click="tabTab(index)"
        v-for="(tab, index) in ['金额明细', '邀请好友']"
        :class="{ active: actTabIndex == index }"
      >
        <div class="tab-label">{{ tab }}</div>
        <div class="tab-highlight"></div>
      </div>
    </div>
    <div class="tab-detail" v-if="actTabIndex == 0">
      <div
        class="list-item"
        v-for="(detail, index) in amountDetail"
        :key="index"
      >
        <div class="item-part-1">
          <div class="item-label">
            {{
              detail.type == 1
                ? '邀请好友成功'
                : detail.status == 1
                ? '提现申请中（72小时之内发放）'
                : '提现成功'
            }}
          </div>
          <div class="item-date">{{ detail.createdAt ? formatToYMDHMS(detail.createdAt) : '-' }}</div>
        </div>
        <div class="item-part-2" :class="{ withdraw: detail.type == 2 }">
          {{ `${detail.type == 2 ? '-' : '+'}${detail.amount}` }}
        </div>
      </div>
    </div>
    <div class="tab-detail" v-else>
      <div
        class="list-item"
        v-for="(detail, index) in invitedPeople"
        :key="index"
      >
        <div class="item-part-3">
          <div class="avatar">
            <img src="../../assets/avatar1.png" />
          </div>
          <div class="item-part-1">
            <div class="item-label">
              {{ detail.email }}
            </div>
            <div class="item-date">{{ detail.createdAt ? formatToYMDHMS(detail.createdAt) : '-' }}</div>
          </div>
        </div>
        <div
          class="item-part-2"
          style="font-size: 0.32rem; color: #53CDA8;font-weight: bold;"
        >
          邀请成功
        </div>
      </div>
    </div>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
  </div>
</template>
<script>
import Toast from '@/components/Toast'
import { reportError } from '@/utils/log'
import { utils } from '@/utils'
import { getInvitationInfo, withdraw } from '@/api/carpool/invitaion'

export default {
  name: 'invitation-bounty-withdraw',
  data () {
    return {
      toastMsg: '',
      showToast: false,
      actTabIndex: 0,
      availableAmount: 0,
      amountDetail: [],
      invitedPeople: []
    }
  },
  components: {
    Toast
  },
  methods: {
    async withdraw () {
      try {
        if (this.availableAmount < 10) {
          this.toastMsg = '可提现余额不足'
          this.showToast = true
        } else {
          let res = await withdraw({
            uuid: localStorage.getItem('uuid')
          })
          if (res.data.code == 0) {
            this.toastMsg = '提现申请成功，48小时内请留意微信服务号的红包，没领取过期作废哦'
            this.showToast = true
          } else {
            this.toastMsg = res.data.message
            this.showToast = true
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    tabTab (index) {
      this.actTabIndex = index
    },
    formatToYMDHMS (date) {
      return utils.formatToYMDHMS(date)
    },
    async getInvitationInfo () {
      try {
        let res = await getInvitationInfo({
          uuid: localStorage.getItem('uuid'),
          type: 2
        })
        if (res.data.code == 0) {
          
          this.availableAmount = res.data.data.availableAmount
          this.invitedPeople = res.data.data.invitedPeople
          this.amountDetail = res.data.data.amountDetail

          console.log(this.amountDetail)
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        reportError(error)
      }
    }
  },
  created () {
    // this.$isWeixin = true
    if (!this.$isWeixin) {
      this.toastMsg = '请在微信打开此网页再进行提现哦'
      this.showToast = true
    } else {
      this.getInvitationInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.invitation-bounty-withdraw {
  display: flex;
  flex-direction: column;
  width: 7.5rem;
  font-size: 0.28rem;
  align-items: center;
  background: #f8f8f8;
  overflow: hidden;
  flex: 1;
  .balance-box {
    width: 7.02rem;
    height: 4.2rem;
    background: #ffffff;
    box-shadow: 0px 0px 0.08rem 0.04rem rgba(0, 0, 0, 0.12);
    border-radius: 0.14rem;
    margin-top: 0.24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .balance-label {
      margin-top: 0.46rem;
    }
    .amount {
      font-size: 0.92rem;
      margin-top: 0.24rem;
    }
    .withdraw-btn {
      width: 2.96rem;
      height: 0.8rem;
      background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      color: #ffffff;
      justify-content: center;
      font-size: 0.28rem;
      margin-top: 0.2rem;
    }
    .withdraw-tips {
      display: flex;
      align-items: center;
      padding-left: 0.24rem;
      flex: 1;
      margin-right: 0.24rem;
      color: #b5b5b5;
    }
  }
  .tabs {
    height: 1.1rem;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .tab-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #bbbbbb;
      position: relative;
      &.active {
        color: #050608;
        .tab-highlight {
          z-index: 0;
          width: 90%;
          margin-top: -0.1rem;
          height: 0.08rem;
          background: linear-gradient(270deg, #ffffff 0%, #53cca8 100%);
          border-radius: 0.05rem;
        }
      }
      .tab-label {
        font-weight: bold;
        font-size: 0.28rem;
        z-index: 1;
      }
      .new-label {
        position: absolute;
        width: 0.4rem;
        height: 0.3rem;
        top: -0.08rem;
        right: -0.36rem;
      }
    }
  }
  .tab-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0 auto;
    overflow-y: auto;
    .list-item {
      display: flex;
      height: 1.56rem;
      background: #ffffff;
      flex: 1 0 auto;
      max-height: 1.56rem;
      padding: 0 0.24rem;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.01rem;
      .item-part-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        border-bottom: 0.01rem #eeeeee solid;
        flex: 1;
        height: 100%;
      }
      .item-part-2 {
        display: flex;
        height: 100%;
        border-bottom: 0.01rem #eeeeee solid;
        align-items: center;
        font-size: 0.32rem;
        color: #ff4d4f;
        &.withdraw {
          color: #53cda8;
        }
      }
      .item-part-3 {
        display: flex;
        border-bottom: 0.01rem #eeeeee solid;
        flex: 1;
        height: 100%;
        align-items: center;
        .avatar {
          height: 0.82rem;
          width: 0.82rem;
          img {
            height: 0.82rem;
            width: 0.82rem;
          }
        }
        .item-part-1 {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          flex: 1;
          height: 70%;
          margin-left: 0.24rem;
          border-bottom: none;
        }
      }
    }
  }
}
</style>
